<template>
  <div class="register-page">
    <div class="page-content header-clear-small">
      <div class="card card-style">
        <div class="content mb-0">
          <h1 class="text-center font-800 font-40 mb-1">Sign Up</h1>
          <br />
          <div>
            <form @submit.prevent="submit">
              <div>
                <div
                  class="input-style has-borders has-icon validate-field mb-4"
                >
                  <i class="input-icon fa fa-user color-theme"></i>
                  <input
                    type="text"
                    name="username"
                    v-model="form.username"
                    class="form-control"
                    placeholder="Enter username..."
                    required
                  />
                  <em>(required)</em>
                </div>
              </div>
              <div>
                <div
                  class="input-style has-borders has-icon validate-field mb-4"
                >
                  <i class="input-icon fa fa-at color-theme"></i>
                  <input
                    type="email"
                    name="email"
                    v-model="form.email"
                    class="form-control"
                    placeholder="Enter email..."
                    required
                  />
                  <em>(required)</em>
                </div>
              </div>
              <div>
                <div
                  class="input-style has-borders has-icon validate-field mb-4"
                >
                  <i class="input-icon fa fa-lock color-theme"></i>
                  <input
                    type="password"
                    name="password"
                    v-model="form.password"
                    class="form-control"
                    placeholder="Enter password..."
                    required
                  />
                  <em>(required)</em>
                </div>
              </div>
              <div>
                <div
                  class="input-style has-borders has-icon validate-field mb-4"
                >
                  <i class="input-icon fa fa-lock color-theme"></i>
                  <input
                    type="password"
                    name="password2"
                    v-model="form.password2"
                    class="form-control"
                    placeholder="Enter password again..."
                    required
                  />
                  <em>(required)</em>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <div class="d-flex justify-content-center">
                      <button
                        type="button"
                        class="
                          btn btn-sm btn-left-l
                          bg-red-dark
                          text-uppercase
                          font-900
                          text-uppercase
                          rounded-s
                          shadow-xl
                          mb-4
                        "
                        @click="back"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div class="col">
                    <div class="message">
                      <notifications position="center" />
                    </div>
                    <div class="d-flex justify-content-center">
                      <button
                        type="submit"
                        class="
                          btn btn-m btn-full
                          mb-3
                          rounded-xs
                          text-uppercase
                          font-900
                          shadow-s
                          bg-green-dark
                        "
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Register",
  components: {},
  data() {
    return {
      form: {
        username: "",
        email: "",
        password: "",
        password2: "",
      },
      showError: false,
    };
  },
  methods: {
    submit() {
      axios
        .post("api/register", {
          username: this.form.username,
          email: this.form.email,
          password: this.form.password,
          password2: this.form.password2,
        })
        .then((response) => {
          this.$router.push("/login");
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          this.form.username = "";
          this.form.email = "";
          this.form.password = "";
          this.form.password2 = "";
          this.$notify({
            title: "Error",
            text: "Username, Email or password is wrong",
            type: "error",
            duration: 1000,
          });
        });
    },
    back() {
      this.$router.push("/login");
    },
  },
};
</script>
