<template>
  <div class="page-content header-clear-small">
    <div class="card card-style">
      <div class="content mb-0">
        <h1 class="text-center font-900 font-40 text-uppercase mb-0">
          Company Page
        </h1>
        <notifications position="center" />
        <div class="input-style has-icon input-style-1 input-required">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="saveCompany">
                <div>
                  <h4 class="d-flex justify-content-center">Name</h4>
                  <div
                    class="
                      input-style
                      has-borders has-icon
                      validate-field
                      mb-4
                      input-required
                    "
                  >
                    <input
                      v-model="dataCompany.name"
                      type="text"
                      class="form-control validate-name"
                      placeholder="Enter company name..."
                      required
                    />
                  </div>
                </div>
                <div>
                  <h4 class="d-flex justify-content-center">Address</h4>
                  <div
                    class="
                      input-style
                      has-borders has-icon
                      validate-field
                      mb-4
                      input-required
                    "
                  >
                    <input
                      v-model="dataCompany.address"
                      type="text"
                      class="form-control validate-address"
                      placeholder="Enter company adress..."
                      required
                    />
                  </div>
                </div>
                <div>
                  <h4 class="d-flex justify-content-center">City</h4>
                  <div
                    class="
                      input-style
                      has-borders has-icon
                      validate-field
                      mb-4
                      input-required
                    "
                  >
                    <input
                      v-model="dataCompany.city"
                      type="text"
                      class="form-control validate-city"
                      placeholder="Enter company city..."
                      required
                    />
                  </div>
                </div>
                <div>
                  <h4 class="d-flex justify-content-center">Zip-Code</h4>
                  <div
                    class="
                      input-style
                      has-borders has-icon
                      validate-field
                      mb-4
                      input-required
                    "
                  >
                    <input
                      v-model="dataCompany.zipcode"
                      class="form-control validate-zip-code"
                      placeholder="Enter company zip-code..."
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div>
                  <h4 class="d-flex justify-content-center">Phone number</h4>
                  <div
                    class="
                      input-style
                      has-borders has-icon
                      validate-field
                      mb-4
                      input-required
                    "
                  >
                    <input
                      v-model="dataCompany.phone_number"
                      class="form-control validate-phone-number"
                      placeholder="Enter company phone number..."
                      type="number"
                      min="0"
                      required
                    />
                  </div>
                </div>
                <h4 class="d-flex justify-content-center">NIP</h4>
                <div
                  class="
                    input-style
                    has-borders has-icon
                    validate-field
                    mb-4
                    input-required
                  "
                >
                  <input
                    v-model="dataCompany.nip"
                    type="number"
                    class="form-control validate-nip"
                    placeholder="Enter company NIP..."
                  />
                </div>
                <h4 class="d-flex justify-content-center">Email smtp</h4>
                <div
                  class="
                    input-style
                    has-borders has-icon
                    validate-field
                    mb-4
                    input-required
                  "
                >
                  <input
                    v-model="dataCompany.email"
                    type="email"
                    class="form-control validate-emialSmtp"
                    placeholder="Enter company email smtp..."
                  />
                </div>
                <h4 class="d-flex justify-content-center">Password smtp</h4>
                <div
                  class="
                    input-style
                    has-borders has-icon
                    validate-field
                    mb-4
                    input-required
                  "
                >
                  <input
                    v-model="dataCompany.password"
                    type="password"
                    class="form-control validate-nip"
                    placeholder="Enter company password smtp..."
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <button
                    type="submit"
                    class="
                      btn btn-m btn-full
                      mb-3
                      rounded-xs
                      text-uppercase
                      font-900
                      shadow-s
                      bg-green-dark
                    "
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MyCompany",
  data() {
    return {
      dataCompany: {
        name: "",
        address: "",
        city: "",
        zipcode: "",
        phone_number: "",
        nip: "",
        id: "",
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    this.getCompanyData();
  },
  methods: {
    getCompanyData() {
      axios.get("api/company").then((response) => {
        this.dataCompany = response.data;
      });
    },
    saveCompany() {
      axios
        .post("api/company", {
          name: this.dataCompany.name,
          address: this.dataCompany.address,
          city: this.dataCompany.city,
          zipcode: this.dataCompany.zipcode,
          phone_number: this.dataCompany.phone_number,
          nip: this.dataCompany.nip,
          email: this.dataCompany.email,
          password: this.dataCompany.password,
        })
        .then((response) => {
          console.log(this.dataCompany.email, this.dataCompany.password);
          this.$notify({
            title: "Success",
            text: response.data.message,
            type: "success",
            duration: 1000,
          });
        })
        .catch((error) => {
          this.$notify({
            title: "Error",
            text: error.message,
            type: "error",
            duration: 1000,
          });
        });
    },
  },
};
</script>
