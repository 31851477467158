<template>
  <div>
    <form @submit="onSubmit" class="add-form">
      <div>
        <div>
          <br />
          <h3 class="d-flex justify-content-center">Product</h3>
          <div>
            <SimpleTypeahead
              ref="test"
              id="typeahead_id"
              :placeholder="options.placeholder"
              :minInputLength="options.minInputLength"
              :items="results"
              @selectItem="selectItem"
              @onInput="onInput"
              :itemProjection="
                (item) => {
                  return item.name;
                }
              "
            >
              <template #list-item-text="slot">
                <span
                  v-html="slot.boldMatchText(slot.itemProjection(slot.item))"
                ></span
              ></template>
            </SimpleTypeahead>
          </div>
        </div>
        <div class="input-style has-icon input-style-1 input-required">
          <div class="content mb-0">
            <h3 class="d-flex justify-content-center">Quantity</h3>
            <input type="number" v-model="quantity" placeholder="Quantity" />
          </div>

          <br />
          <div class="d-flex justify-content-center">
            <button
              type="submit"
              class="
                btn btn-m btn-full
                mb-3
                rounded-xs
                text-uppercase
                font-900
                shadow-s
                bg-green-dark
              "
            >
              Add Product
            </button>
          </div>
        </div>
        <div class="message">
          <notifications position="center" />
        </div>
        <br />
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";

export default {
  name: "AddProduct",
  components: {
    SimpleTypeahead,
  },
  data() {
    return {
      options: {
        placeholder: "Start writing...",
        minInputLength: 2,
      },
      data: {
        selection: null,
      },

      results: [],
      quantity: null,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (!this.data.selection.id) {
        console.log(this.data.selection.id);
        alert("Please add a Product");
        return;
      }
      const newProduct = {
        product_id: this.data.selection.id,
        quantity: this.quantity,
      };
      this.$emit("add-product", newProduct);
      this.data.selection = null;
      this.$notify({
        title: `New product ${this.$refs.test.input} added`,
        type: "success",
        duration: 1000,
      });
      this.$refs.test.input = "";
      this.quantity = "";
    },

    selectItem(item) {
      this.data.selection = item;
      this.data.input = item;
    },
    onInput(event) {
      this.data.input = event.input;
      axios
        .get(`order/new_order/search_product`, {
          params: {
            search_text: this.data.input,
          },
        })
        .then((response) => {
          this.results = response.data;
          // console.log("onInput: ", this.data.input);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
#typeahead_id {
  border: none;
  padding-left: 30px;
  width: 100%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
}
.simple-typeahead .simple-typeahead-list[data-v-04d98098] {
  position: static;
  width: 100%;
  border: none;
  max-height: 400px;
  overflow: scroll;
  border-bottom: 0.1rem solid #d1d1d1;
  z-index: 9;
}
.simple-typeahead
  .simple-typeahead-list
  .simple-typeahead-list-item[data-v-04d98098] {
  cursor: pointer;
  background-color: #fafafa;
  padding: 0.3rem 1rem;
  border-bottom: 0.1rem solid #d1d1d1;
  border-left: 0.1rem solid #d1d1d1;
  border-right: 0.1rem solid #d1d1d1;
}
</style>
