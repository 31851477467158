<template>
  <div class="page-content header-clear-small">
    <div class="card card-style">
      <div class="content mb-0">
        <h1 class="text-center font-900 font-40 text-uppercase mb-0">Chart</h1>
        <br />
        <Datepicker
          v-model="date"
          type="date"
          range
          format="MM/dd/yyyy"
          lang="en"
          placeholder="Select date"
        ></Datepicker>
      </div>
      <br />
      <div class="d-flex justify-content-center">
        <button
          class="
            btn btn-m btn-full
            mb-3
            rounded-xs
            text-uppercase
            font-900
            shadow-s
            bg-green-dark
          "
          @click="getCategories"
        >
          Update
        </button>
      </div>
      <br />
      <div v-if="date === ''">
        <div></div>
      </div>
      <div v-else-if="objCategory.category.length">
        <div>
          <CategoryChart :v-if="loading" :objCategory="this.objCategory" />
        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-content-center">
          <p class="opacity-100 font-12 mt-n1">
            <strong>Select other dates</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CategoryChart from "@/components/CategoryChart.vue";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { reactive } from "vue";

export default {
  name: "Chart",
  components: {
    CategoryChart,
    Datepicker,
  },
  data() {
    return {
      loading: false,
      date: "",
      objCategory: reactive({ category: "", totalPrice: "" }),
    };
  },
  methods: {
    async getCategories() {
      await axios
        .get("api/orders/category/chart", {
          params: {
            date_start: new Date(this.date[0]).getTime() / 1000,
            date_end: new Date(this.date[1]).getTime() / 1000,
          },
        })
        .then((response) => {
          this.data = response.data;
          var category = [];
          var totalPrice = [];
          this.data.forEach((element) => {
            category.push(element.category);
            totalPrice.push(element.total_price);
          });
          this.objCategory.category = category;
          this.objCategory.totalPrice = totalPrice;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
