<template>
  <div class="category">
    <div class="page-content header-clear-small">
      <div class="card card-style">
        <div class="content mb-0">
          <h1 class="text-center font-900 font-40 text-uppercase mb-0">
            Supplier Page
          </h1>
          <div>
            <form @submit.prevent="createSupplier">
              <br />
              <div class="d-flex justify-content-center">
                <h3>Name</h3>
              </div>
              <div class="input-style has-borders has-icon validate-field mb-4">
                <i class="fa fa-user"> </i>
                <input
                  type="text"
                  required
                  class="form-control validate-name"
                  v-model="supplierName"
                  placeholder="Enter new supplier"
                  minlength="2"
                />
                <em>(required)</em>
              </div>
              <div class="d-flex justify-content-center">
                <h3>Email address</h3>
              </div>
              <div class="input-style has-borders has-icon validate-field mb-4">
                <i class="fa fa-at"> </i>
                <input
                  type="email"
                  required
                  v-model="supplierEmail"
                  class="form-control validate-email"
                  placeholder="Enter email supplier"
                  minlength="2"
                />
                <em>(required)</em>
              </div>
              <br />
              <div class="message">
                <notifications position="center" />
              </div>
              <div class="d-flex justify-content-center">
                <button
                  type="submit"
                  class="
                    btn btn-m btn-full
                    mb-3
                    rounded-xs
                    text-uppercase
                    font-900
                    shadow-s
                    bg-green-dark
                  "
                >
                  Add New Supplier
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="card card-style">
        <div class="content mb-0">
          <div class="d-flex justify-content-center">
            <h1 class="text-center font-900 font-40 text-uppercase mb-0">
              List supplier
            </h1>
          </div>
          <br />
          <div class="input-style has-icon input-style-1 input-required">
            <div class="container">
              <div
                class="card card-style mb-1"
                v-for="supplier in allSuppliers"
                :key="supplier"
              >
                <div class="list-group-item">
                  <div class="d-flex">
                    <div class="ps-0 ms-2 align-self-center">
                      <h3>{{ supplier.name }}</h3>
                      <p>{{ supplier.email }}</p>
                    </div>
                    <div class="ms-auto me-4 align-self-center">
                      <i
                        class="fa font-20 color-highlight bg-theme fa-times"
                        @click="removeSupplier(supplier.id)"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Supplier",
  components: {},
  data() {
    return {
      allSuppliers: [],
      supplierName: "",
      supplierEmail: "",
    };
  },
  mounted() {
    this.getAllSuppliers();
  },
  methods: {
    async getAllSuppliers() {
      await axios
        .get("api/supplier")
        .then((response) => {
          this.allSuppliers = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createSupplier() {
      axios
        .post("api/supplier", {
          name: this.supplierName,
          email: this.supplierEmail,
        })
        .then((response) => {
          this.allSuppliers.push({
            name: this.supplierName,
            email: this.supplierEmail,
            id: response.data.id,
          });
          this.$notify({
            title: `New supplier ${this.supplierName} added`,
            type: "success",
            duration: 1000,
          });
          this.supplierName = "";
          this.supplierEmail = "";
        })
        .catch(() => {
          this.supplierName = "";
          this.supplierEmail = "";
          this.$notify({
            title: "Error",
            text: "The name or e-mail address is already taken",
            type: "error",
            duration: 1000,
          });
        });
    },
    removeSupplier(id) {
      axios
        .delete(`api/supplier/${id}`)
        .then((response) => {
          this.allSuppliers.pop(id);
          this.$notify({
            title: response.data.message,
            type: "info",
            duration: 1000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: error.response.data.name,
            type: "error",
            duration: 1000,
          });
        });
    },
  },
};
</script>
