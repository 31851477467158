<template>
  <div class="d-flex justify-content-center">
    <notifications position="top center" />
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <button
              class="
                btn btn-sm btn-left
                bg-red-dark
                text-uppercase
                font-900
                text-uppercase
                rounded-s
                shadow-xl
                mb-4
              "
              @click="downloadPDF"
            >
              Download PDF
            </button>
          </div>
        </div>
        <div class="col">
          <div class="d-flex justify-content-center">
            <span class="label-supplier">Supplier:</span>
            <br />
            <select v-model="selectedSupplier" required>
              <option disabled value="">Please select one</option>
              <option
                v-for="supplier in suppliers"
                :key="supplier"
                v-bind:value="supplier.email"
              >
                {{ supplier.name }}
              </option>
            </select>
          </div>
          <br />
          <div class="d-flex justify-content-center">
            <button
              type="button"
              class="
                btn btn-m btn-full
                mb-3
                rounded-xs
                text-uppercase
                font-900
                shadow-s
                bg-green-dark
              "
              :disabled="selectedSupplier.length == ''"
              @click="sendPDFEmail"
            >
              Send PDF
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import "../assets/fonts_pdf/Roboto-Regular-normal.js";

export default {
  name: "PDF",
  props: {
    order: Array,
  },
  data() {
    return {
      companyData: null,
      suppliers: null,
      selectedSupplier: "",
      fullPage: true,
    };
  },
  mounted() {
    this.getSuppliers();
  },
  methods: {
    async downloadPDF() {
      const pdf = await this.renderPDF();

      let today = new Date().toLocaleDateString();

      pdf.save(`Order: ${today}`);

      // window.open(render.output("bloburl"), "_blank");
    },

    getSuppliers() {
      axios
        .get("api/supplier")
        .then((response) => {
          this.suppliers = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getCompany() {
      const company = await axios.get("api/company");

      this.companyData = company.data;
    },
    async renderPDF() {
      await this.getCompany();
      let pdf = new jsPDF();
      pdf.addFont("Roboto-Regular-normal.ttf", "Roboto-Regular", "normal");
      pdf.setFont("Roboto-Regular");

      let today = new Date().toLocaleDateString();

      pdf.setProperties({
        title: `Order: ${today}`,
      });
      pdf.setFontSize(10);
      pdf.text(["Data", today], 10, 20);
      pdf.text(["Nazwa", this.companyData.name], 10, 30);
      pdf.text(["Adress", this.companyData.address], 10, 40);
      pdf.text(["City", this.companyData.city], 10, 50);
      pdf.text(["Email", this.companyData.email], 10, 60);
      pdf.text(["NIP", this.companyData.nip], 10, 70);

      let columns = ["Product name", "Quantity"];
      let rows = [];

      this.order.forEach((element) => {
        let newArray = new Array();
        newArray.push(element.product.name, element.quantity);
        rows.push(newArray);
      });

      pdf.autoTable({
        theme: "grid",
        margin: { top: 80 },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.5,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 14,
          padding: 0,
        },
        styles: {
          halign: "center",
          fontSize: 10,
          font: "Roboto-Regular",
          cellPadding: 2,
          minCellHeight: 2,
        },
        head: [columns],
        body: rows,
      });
      return pdf;
    },
    async sendPDFEmail() {
      let loader = this.$loading.show();
      await this.getSuppliers();
      let render = await this.renderPDF();
      // let date = new Date().toISOString().slice(0, 10);
      let date = new Date().toLocaleDateString("en-CA");
      let pdf = render.output("blob");
      let formData = new FormData();
      let today = `Zamówienie: ${date.toString()}`;
      formData.append("pdf_file", pdf, today);
      formData.append("supplier", this.selectedSupplier);
      axios
        .post(`api/send_email`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          loader.hide();
          window.scrollTo(0, 0);
          this.$notify({
            title: "Success",
            text: response.data.message,
            type: "success",
            duration: 2000,
          });
        })
        .catch((error) => {
          loader.hide();
          window.scrollTo(0, 0);
          this.$notify({
            title: "Success",
            text: error.data.message,
            type: "success",
            duration: 2000,
          });
        });
    },
  },
};
</script>
