<template>
  <div class="price-list">
    <div class="page-content header-clear-small">
      <div class="message">
        <notifications position="center" />
      </div>
      <div class="page-content header-clear- small">
        <div class="card card-style">
          <div class="content mb-0">
            <div class="table-responsive-sm">
              <table
                class="table table-borderless text-center rounded-sm shadow-m"
                style="overflow: hidden"
              >
                <thead>
                  <tr class="bg-green-dark">
                    <th scope="col" class="color-white">Material Name</th>
                    <th scope="col" class="color-white">Category</th>
                    <th scope="col" class="color-white">Price</th>
                    <th scope="col" class="color-white">Edit</th>
                    <th scope="col" class="color-white">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in allPriceList" :key="item" class="border">
                    <td>
                      <strong>{{ item.name }}</strong>
                    </td>
                    <td>{{ item.category.name }}</td>
                    <td>{{ item.price }}</td>
                    <td>
                      <i class="fa font-15 fa-edit color-blue-dark"></i>
                    </td>
                    <td>
                      <i
                        class="fa font-15 color-highlight bg-theme fa-times"
                        @click="removeProduct(item.id)"
                      >
                      </i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "PriceList",
  components: {},
  data() {
    return {
      allPriceList: [],
      fullPage: true,
    };
  },
  mounted() {
    this.getAllPriceList();
  },
  methods: {
    async getAllPriceList() {
      let loader = this.$loading.show();
      await axios
        .get("api/product")
        .then((response) => {
          loader.hide();
          this.allPriceList = response.data;
          console.log(this.allPriceList);
        })
        .catch((error) => {
          loader.hide();
          console.log(error);
        });
    },
    removeProduct(id) {
      console.log(id);
      axios
        .delete(`api/product/${id}`)
        .then((response) => {
          this.allPriceList.pop(id);
          this.$notify({
            title: response.data.message,
            type: "info",
            duration: 1000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: error.response.data.name,
            type: "error",
            duration: 1000,
          });
        });
    },
  },
};
</script>
