<template>
  <div class="history-detail">
    <div class="page-content header-clear-small">
      <div class="card card-style">
        <!-- <notifications position="center" /> -->
        <div class="content mb-0">
          <h1 class="text-center font-900 font-40 text-uppercase mb-0">
            Order {{ id }}
          </h1>
          <AddProduct @add-product="addProduct" />
          <br />
        </div>
      </div>
      <div>
        <div class="page-content header-clear-xsmall">
          <div class="page-content header-clear- small">
            <div class="card card-style">
              <div class="content mb-2">
                <div class="table-responsive-sm">
                  <table
                    class="
                      table table-borderless
                      text-center
                      rounded-sm
                      shadow-l
                    "
                    style="overflow: hidden"
                  >
                    <thead>
                      <tr class="bg-green-dark">
                        <th scope="col" class="color-white">Name</th>
                        <th scope="col" class="color-white">Price</th>
                        <th scope="col" class="color-white">Quantity</th>
                        <th scope="col" class="color-white">Total</th>
                        <th scope="col" class="color-white">Remove</th>
                      </tr>
                    </thead>
                    <tbody v-for="item in order" :key="item">
                      <tr class="border">
                        <td>{{ item.product.name }}</td>
                        <td>{{ item.product.price }} zł</td>
                        <td>{{ item.quantity }}</td>
                        <td>
                          {{ (item.quantity * item.product.price).toFixed(2) }}
                          zł
                        </td>
                        <td>
                          <i
                            class="fa font-20 color-highlight bg-theme fa-times"
                            @click="removeProductOrder(item.id)"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-center">
                    <h1>{{ calcSum }} zł</h1>
                  </div>
                  <br />
                  <div class="d-flex justify-content-center">
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <div class="d-flex justify-content-center">
                            <button
                              type="button"
                              class="
                                btn btn-sm btn-left-l
                                bg-red-dark
                                text-uppercase
                                font-900
                                text-uppercase
                                rounded-s
                                shadow-xl
                                mb-4
                              "
                              @click="back"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                        <div class="col">
                          <div class="d-flex justify-content-center">
                            <button
                              class="
                                btn btn-m btn-full
                                mb-3
                                rounded-xs
                                text-uppercase
                                font-900
                                shadow-s
                                bg-green-dark
                              "
                              @click="saveOrder(id)"
                            >
                              Save order
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AddProduct from "@/components/AddProduct.vue";

export default {
  components: {
    AddProduct,
  },
  data() {
    return {
      order: [],
    };
  },
  props: ["id"],
  mounted() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      const order_id = this.$route.params.id;
      axios
        .get(`/api/order/active/${order_id}/`)
        .then((response) => {
          this.order = response.data.items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    back() {
      this.$router.push({ name: "Orders" });
    },
    addProduct(newProduct) {
      axios
        .post("/api/productOrder/create", {
          product_id: newProduct.product_id,
          quantity: newProduct.quantity,
          order_id: this.$route.params.id,
        })
        .then(() => {
          this.getOrder();
          // console.log(response);
          // this.$notify({
          //   title: `New product added`,
          //   type: "success",
          //   duration: 1000,
          // });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveOrder(id) {
      axios
        .post(`/api/order/active/${id}/`)
        .then(() => {
          console.log("Order save");
          this.$router.push({ name: "Orders" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeProductOrder(id) {
      axios
        .delete(`api/productOrder/update/${id}`)
        .then((response) => {
          this.order.pop(id);
          this.$notify({
            title: response.data.message,
            type: "info",
            duration: 1000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: error.response.data.name,
            type: "error",
            duration: 1000,
          });
        });
    },
  },
  computed: {
    calcSum() {
      return this.order
        .reduce((acc, curVal) => {
          return (acc += curVal.product.price * curVal.quantity);
        }, 0)
        .toFixed(2);
    },
  },
};
</script>
