<template>
  <div class="category">
    <div class="page-content header-clear-small">
      <div class="card card-style">
        <div class="content mb-0">
          <h1 class="text-center font-900 font-40 text-uppercase mb-0">
            Add Category
          </h1>
          <div>
            <form @submit.prevent="createCategory">
              <br />
              <div class="d-flex justify-content-center">
                <h3>Category</h3>
              </div>
              <div class="input-style has-borders has-icon validate-field mb-4">
                <i class="fa fa-pen color-blue-dark"></i>
                <input
                  type="text"
                  class="form-control validate-category"
                  v-model="categoryName"
                  placeholder="Enter new category"
                  minlength="2"
                />
              </div>
              <br />
              <div class="message">
                <notifications position="center" />
              </div>
              <div class="d-flex justify-content-center">
                <button
                  type="submit"
                  class="
                    btn btn-m btn-full
                    mb-3
                    rounded-xs
                    text-uppercase
                    font-900
                    shadow-s
                    bg-green-dark
                  "
                >
                  Add Category
                </button>
              </div>
              <br />
            </form>
          </div>
        </div>
      </div>
      <div class="card card-style">
        <div class="content mb-0">
          <div class="d-flex justify-content-center">
            <h1 class="text-center font-900 font-40 text-uppercase mb-0">
              List category
            </h1>
          </div>
          <br />
          <div>
            <div
              v-for="category in allCategories"
              :key="category"
              class="card card-style mb-1"
            >
              <div class="list-group-item">
                <div class="d-flex">
                  <div class="ps-0 ms-2 align-self-center">
                    <h3>{{ category.name }}</h3>
                  </div>
                  <div class="ms-auto me-4 align-self-center">
                    <i
                      class="fa font-20 color-highlight bg-theme fa-times"
                      @click="removeCategory(category.id)"
                    >
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Category",
  components: {},
  data() {
    return {
      allCategories: [],
      categoryName: "",
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      await axios
        .get("api/category")
        .then((response) => {
          this.allCategories = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createCategory() {
      axios
        .post("api/category", {
          name: this.categoryName,
        })
        .then((response) => {
          this.allCategories.push({
            name: this.categoryName,
            id: response.data.id,
          });
          this.$notify({
            title: `New category ${this.categoryName} added`,
            type: "success",
            duration: 1000,
          });
          this.categoryName = "";
        })
        .catch((error) => {
          this.categoryName = "";
          this.$notify({
            title: error.response.data.name,
            type: "error",
            duration: 1000,
          });
        });
    },
    removeCategory(id) {
      axios
        .delete(`api/category/${id}`)
        .then((response) => {
          this.allCategories.pop(id);
          this.$notify({
            title: response.data.message,
            type: "info",
            duration: 1000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: error.response.data.name,
            type: "error",
            duration: 1000,
          });
        });
    },
  },
};
</script>
