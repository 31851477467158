import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Orders from "../views/Orders.vue";
import Category from "../views/Category.vue";
import PriceList from "../views/PriceList.vue";
import Order from "../views//orders/Order.vue";
import About from "../views/About.vue";
import History from "../views/History.vue";
import HistoryOrderDetail from "../views/HistoryOrderDetail.vue";
import Chart from "../views/Chart.vue";
import MyCompany from "../views/MyCompany.vue";
import Supplier from "../views/Supplier.vue";
import Product from "../views/Product.vue";
import store from "../store";

import Register from "../views/Register.vue";
import Login from "../views/Login.vue";

const routes = [
  {
    path: "/",
    redirect: {
      name: "Login",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
  },

  {
    path: "/category",
    name: "Category",
    component: Category,
  },
  {
    path: "/price-list",
    name: "PriceList",
    component: PriceList,
  },
  {
    path: "/order-detail/:id",
    name: "Order",
    component: Order,
    props: true,
  },

  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/history-order-detail/:id",
    name: "HistoryOrderDetail",
    component: HistoryOrderDetail,
    props: true,
  },
  {
    path: "/chart",
    name: "Chart",
    component: Chart,
  },
  {
    path: "/my-company",
    name: "MyCompany",
    component: MyCompany,
  },
  {
    path: "/supplier",
    name: "Supplier",
    component: Supplier,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requireLogin) &&
    !store.state.isAuthenticated
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
