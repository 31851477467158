<template>
  <div class="product-page">
    <div class="card card-style">
      <notifications position="center" />
      <div class="content mb-0">
        <h1 class="text-center font-900 font-40 text-uppercase mb-0">
          Add Product
        </h1>
        <form @submit.prevent="createProduct">
          <div class="input-style has-icon input-style-1 input-required">
            <br />
            <div class="d-flex justify-content-center">
              <h1>Category</h1>
            </div>
            <div class="list-group list-custom-large short-border">
              <div class="row">
                <div class="col-10">
                  <select v-model="selectedCategory" required>
                    <option disabled value="">Please select one</option>
                    <option v-for="category in allCategories" :key="category">
                      {{ category.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <h1>Material name</h1>
            </div>
            <br />
            <input
              type="text"
              required
              class="form-control"
              v-model="productName"
              placeholder="Enter new product"
              minlength="2"
            />
            <br />
            <br />
            <div class="d-flex justify-content-center">
              <h1>Price</h1>
            </div>
            <input
              type="number"
              required
              v-model="productPrice"
              class="form-control"
              placeholder="Enter price"
              min="0.01"
              step="0.01"
            />
            <br />
            <div class="d-flex justify-content-center">
              <button
                type="submit"
                class="
                  btn btn-m btn-full
                  mb-3
                  rounded-xs
                  text-uppercase
                  font-900
                  shadow-s
                  bg-green-dark
                "
              >
                Add Product
              </button>
            </div>
          </div>
        </form>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Product",
  components: {},
  data() {
    return {
      allCategories: [],
      productName: "",
      productPrice: "",
      selectedCategory: "",
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      await axios
        .get("api/category")
        .then((response) => {
          this.allCategories = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createProduct() {
      axios
        .post("api/product", {
          name: this.productName,
          price: this.productPrice,
          category: this.selectedCategory,
        })
        .then((response) => {
          console.log(response);
          this.$notify({
            title: `New product ${this.productName} added`,
            type: "success",
            duration: 1000,
          });
          this.productName = "";
          this.selectedCategory = "";
          this.productPrice = "";
        })
        .catch((error) => {
          this.productName = "";
          this.selectedCategory = "";
          this.productPrice = "";
          this.$notify({
            title: error.response.data.name,
            type: "error",
            duration: 1000,
          });
        });
    },
  },
};
</script>
