import { createStore } from "vuex";

export default createStore({
  state: {
    access: "",
    refresh: "",
    isAuthenticated: false,
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("access")) {
        state.access = localStorage.getItem("access");
        state.isAuthenticated = true;
      } else {
        state.access = "";
        state.refresh = "";
        state.isAuthenticated = false;
      }
    },
    setAccess(state, access) {
      state.access = access;
      state.isAuthenticated = true;
    },
    setRefreshToken(state, refresh) {
      state.refresh = refresh;
    },
    removeToken(state) {
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      state.isAuthenticated = false;
    },
  },
  actions: {},
  modules: {},
});
