<template>
  <div id="page">
    <div class="page-content header-clear-small">
      <div class="row text-center mb-0">
        <router-link :to="{ name: 'Product' }" class="col-6 pe-0">
          <div class="card card-style me-2 mb-3">
            <i class="fa fa-edit color-green-dark fa-3x mt-5"></i>
            <h1 class="pt-4">Add Product</h1>
            <p class="font-11 opacity-50 mt-n2">Add a new product to the db</p>
          </div>
        </router-link>
        <router-link :to="{ name: 'PriceList' }" class="col-6 ps-0">
          <div class="card card-style ms-2 mb-3">
            <i class="fa fa-dollar-sign color-green-dark fa-3x mt-5"></i>
            <h1 class="pt-4">Pricelist</h1>
            <p class="font-11 opacity-50 mt-n2">Check material prices</p>
          </div>
        </router-link>
        <router-link :to="{ name: 'Orders' }" class="col-6 pe-0">
          <div class="card card-style me-2 mb-3">
            <i class="far fa-file-alt color-green-dark fa-3x mt-5"></i>
            <h1 class="pt-4">Add Order</h1>
            <p class="font-11 opacity-50 mt-n2">Add a new order</p>
          </div>
        </router-link>
        <router-link :to="{ name: 'History' }" class="col-6 ps-0">
          <div class="card card-style ms-2 mb-3">
            <i class="fa fa-align-left color-green-dark fa-3x mt-5"></i>
            <h1 class="pt-4">History</h1>
            <p class="font-11 opacity-50 mt-n2">Check your order</p>
          </div>
        </router-link>
        <router-link :to="{ name: 'Category' }" class="col-6 pe-0">
          <div class="card card-style me-2 mb-3">
            <i class="fa fa-list color-green-dark fa-3x mt-5"></i>
            <h1 class="pt-4">Category</h1>
            <p class="font-11 opacity-50 mt-n2">Add a new category to the db</p>
          </div>
        </router-link>
        <router-link :to="{ name: 'Chart' }" class="col-6 ps-0">
          <div class="card card-style ms-2 mb-3">
            <i class="fa fa-chart-area color-green-dark fa-3x mt-5"></i>
            <h1 class="pt-4">Charts</h1>
            <p class="font-11 opacity-50 mt-n2">See orders charts</p>
          </div>
        </router-link>
        <router-link :to="{ name: 'MyCompany' }" class="col-6 pe-0">
          <div class="card card-style me-2 mb-3">
            <i class="fa fa-building color-green-dark fa-3x mt-5 me-0"></i>
            <h1 class="pt-4">Company</h1>
            <p class="font-11 opacity-50 mt-n2">Company details</p>
          </div>
        </router-link>
        <router-link :to="{ name: 'Supplier' }" class="col-6 ps-0">
          <div class="card card-style ms-2 mb-3">
            <i class="fa fa-truck color-green-dark fa-3x mt-5 me-0"></i>
            <h1 class="pt-4">Supplier</h1>
            <p class="font-11 opacity-50 mt-n2">Supplier details</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
