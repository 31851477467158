<template>
  <div id="page">
    <div id="footer-bar" class="footer-bar-1">
      <router-link :to="{ name: 'Home' }"
        ><i class="fa fa-home"></i><span>Home</span></router-link
      >
      <router-link @click="logout()" :to="{ name: 'Login' }"
        ><i class="fas fa-sign-out-alt"></i><span>Logout</span></router-link
      >
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  beforeCreate() {
    console.log(this.$store.state.access);
    this.$store.commit("initializeStore");
    const access = this.$store.state.access;
    if (access) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + access;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
  // mounted() {
  //   setInterval(() => {
  //     this.getAccess()
  //   }, 300000)
  // },
  methods: {
    getAccess() {
      const accessData = {
        refresh: this.$store.state.refresh,
      };
      axios
        .post("api/token/refresh/", accessData)
        .then((response) => {
          const access = response.data.access;
          localStorage.setItem("access", access);
          this.$store.commit("setAccess", access);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logout() {
      axios.defaults.headers.common["Authorization"] = "";
      this.$store.commit("removeToken");
    },
  },
};
</script>
<style></style>
