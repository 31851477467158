<template>
  <div class="page-content header-clear-small">
    <div class="card card-style">
      <div class="content mb-0">
        <div class="d-flex justify-content-center">
          <h1 class="text-center font-900 font-40 text-uppercase mb-0">
            ACTIVE ORDERS
          </h1>
        </div>
        <br />
        <div v-for="order in orders" :key="order" class="card card-style mb-1">
          <router-link
            :to="{ name: 'Order', params: { id: order.id } }"
            class="list-group-item"
          >
            <div class="d-flex">
              <div class="ps-0 ms-2 align-self-center">
                <h3>Orders: {{ order.id }}</h3>
                <p class="font-1 opacity-100 mt-n2 mb-0">
                  Dated created: <strong>{{ order.date_created }}</strong>
                </p>
              </div>
              <div class="ms-auto me-4 align-self-center">
                <i
                  class="fa font-25 color-highlight bg-theme fa-times"
                  @click="deleteOrder(order.id)"
                ></i>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div>
        <br />
        <div class="d-flex justify-content-center">
          <button
            type="button"
            class="
              btn btn-m btn-full
              mb-3
              rounded-xs
              text-uppercase
              font-900
              shadow-s
              bg-green-dark
            "
            @click="createNewOrder"
          >
            Create new Order
          </button>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Orders",
  data() {
    return {
      orders: {},
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      await axios
        .get("/api/order/active")
        .then((response) => {
          this.orders = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createNewOrder() {
      axios
        .post("/api/order/active")
        .then(() => {
          console.log("OK!");
          this.getOrders();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteOrder(id) {
      console.log(id);
      if (confirm("Are you sure?")) {
        axios
          .delete(`/api/order/active/${id}/`)
          .then(() => {
            this.$router.push("/orders");
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    },
  },
};
</script>
<style></style>
