<template>
  <div class="login-page">
    <div class="page-content header-clear-small">
      <div class="card card-style">
        <div class="content mb-0">
          <h1 class="text-center font-900 font-40 text-uppercase mb-0">
            Login
          </h1>
          <br />
          <form @submit.prevent="submitForm">
            <div>
              <h3 class="d-flex justify-content-center">Username</h3>
              <div class="input-style has-borders has-icon validate-field mb-4">
                <i class="input-icon fa fa-user color-theme"></i>
                <label>Username</label>
                <input
                  type="text"
                  name="username"
                  v-model="form.username"
                  class="form-control"
                  placeholder="Enter username..."
                  required
                />
                <em>(required)</em>
              </div>
            </div>
            <div>
              <h3 class="d-flex justify-content-center">Password</h3>
              <div class="input-style has-borders has-icon validate-field mb-4">
                <i class="input-icon fa fa-lock color-theme"></i>
                <input
                  type="password"
                  autocomplete="password"
                  name="password"
                  v-model="form.password"
                  class="form-control"
                  placeholder="Enter password..."
                  required
                />
                <em>(required)</em>
              </div>
            </div>
            <notifications position="center" />
            <br />
            <br />
            <div class="d-flex justify-content-center">
              <button
                type="submit"
                class="
                  btn btn-m btn-full
                  mb-3
                  rounded-xs
                  text-uppercase
                  font-900
                  shadow-s
                  bg-green-dark
                "
              >
                Login
              </button>
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <button
              @click="createAccount()"
              type="submit"
              class="
                btn btn-m btn-full
                rounded-s
                shadow-l
                bg-green1-dark
                font-900
              "
            >
              Create Account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.showError = false;
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");

      const formData = {
        username: this.form.username,
        password: this.form.password,
      };
      axios
        .post("/api/token/", formData)
        .then((response) => {
          // console.log(response)

          const access = response.data.access;
          const refresh = response.data.refresh;

          this.$store.commit("setAccess", access);
          this.$store.commit("setRefreshToken", refresh);

          axios.defaults.headers.common["Authorization"] = "Bearer " + access;

          localStorage.setItem("access", access);
          localStorage.setItem("refresh", refresh);

          this.$router.push("/home");
        })
        .catch((error) => {
          console.log(axios.defaults.headers.common["Authorization"]);
          console.log(error);
          this.$notify({
            title: "Username or Password is incorrect",
            type: "error",
            duration: 1000,
          });
          this.form.username = "";
          this.form.password = "";
        });
    },
    createAccount() {
      this.$router.push("/register");
    },
  },
};
</script>
