<template>
  <div class="page-content header-clear-small">
    <div class="card card-style">
      <div class="content mb-0">
        <div class="d-flex justify-content-center">
          <h1 class="text-center font-900 font-40 text-uppercase mb-0">
            History Page
          </h1>
        </div>
        <br />
        <div>
          <p
            class="card card-style mb-1"
            v-for="order in historyOrders"
            :key="order"
          >
            <router-link
              class="list-group-item"
              :to="{
                name: 'HistoryOrderDetail',
                params: {
                  id: order.id,
                  date_created: order.date_created,
                  date_end: order.date_end,
                },
              }"
            >
              <h3>Orders: {{ order.id }}</h3>
              <p class="opacity-100 font-12 mt-n1">
                Create Order: <strong>{{ order.date_created }}</strong>
                <br />
                End Order:
                <strong>{{ order.date_end }}</strong>
              </p>
            </router-link>
          </p>
          <br />
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      historyOrders: {},
      fullPage: true,
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      let loader = this.$loading.show();
      await axios
        .get("api/history/order")
        .then((response) => {
          loader.hide();
          this.historyOrders = response.data;
          this.historyOrders.reverse();
        })
        .catch((error) => {
          loader.hide();
          console.log(error);
        });
    },
  },
};
</script>
