<template>
  <div>
    <div class="d-flex justify-content-center">
      <div style="height: 400px; width: 400px">
        <vue3-chart-js
          :id="pieChart.id"
          ref="chartRef"
          :type="pieChart.type"
          :data="pieChart.data"
          :options="pieChart.options"
        ></vue3-chart-js>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { ref } from "vue";

export default {
  name: "Category Chart",
  components: {
    Vue3ChartJs,
  },
  props: {
    objCategory: Object,
  },
  setup(props) {
    const chartRef = ref(null);
    const pieChart = {
      id: "pie",
      type: "pie",
      data: {
        labels: props.objCategory.category,
        datasets: [
          {
            backgroundColor: [
              "#41B883",
              "#E46651",
              "#00D8FF",
              "#DD1B16",
              "#61444c",
              "#003EFF",
              "FFBF00",
              "#00FFFF",
              "#3366CC",
              "##006633",
              "#9F9FDF",
              "#FFC0CB",
              "#F9E04B",
              "#DED5D0",
              "#19A56F",
              "#DD9ECD",
              "#33CC66",
              "#FFE1BE",
              "#FF00FF",
              "#800000",
            ],
            borderWidth: 1,
            data: props.objCategory.totalPrice,
          },
        ],
      },
      options: {
        plugins: {
          title: {
            text: "Category Chart",
            display: true,
          },
        },
      },
    };
    const updateChart = () => {
      pieChart.data.labels = props.objCategory.category;
      pieChart.data.datasets = [
        {
          backgroundColor: [
            "#41B883",
            "#E46651",
            "#00D8FF",
            "#DD1B16",
            "#61444c",
            "#003EFF",
            "FFBF00",
            "#00FFFF",
            "#3366CC",
            "##006633",
            "#9F9FDF",
            "#FFC0CB",
            "#F9E04B",
            "#DED5D0",
            "#19A56F",
            "#DD9ECD",
            "#33CC66",
            "#FFE1BE",
            "#FF00FF",
            "#800000",
          ],
          data: props.objCategory.totalPrice,
        },
      ];
      chartRef.value.update(250);
    };
    return {
      pieChart,
      chartRef,
      updateChart,
    };
  },
  watch: {
    objCategory: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
  },
};
</script>
