import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import store from "./store";
import Notifications from "@kyvg/vue3-notification";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// Theme
import "./assets/styles/style.css";
import "./assets/styles/custom.css";
// import "./assets/font-awesome/min.css";

// axios.defaults.baseURL = "http://127.0.0.1:8000";
axios.defaults.baseURL = "https://api.dentalist.programowanieodkuchni.pl";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("access");
createApp(App)
  .use(store)
  .use(router, axios)
  .use(Notifications)
  .use(VueLoading, {
    color: "#8cc152",
    zIndex: 999,
    width: 64,
    height: 64,
  })
  .mount("#app");
