<template>
  <div class="page-content header-clear-small">
    <div class="page-content header-clear- small">
      <div class="card card-style">
        <div class="content mb-2">
          <div class="content mb-0">
            <h1 class="text-center font-900 font-40 text-uppercase mb-0">
              Order {{ orderDetail.id }}
            </h1>
            <div class="d-flex justify-content-center">
              <p class="opacity-100 font-12 mt-n1">
                <strong
                  >{{ orderDetail.date_created }} -
                  {{ orderDetail.date_end }}</strong
                >
              </p>
            </div>
          </div>
          <br />
          <table
            class="table table-borderless text-center rounded-sm shadow-l"
            style="overflow: hidden"
          >
            <thead>
              <tr class="bg-green-dark">
                <th scope="col" class="color-white">Produkt name</th>
                <th scope="col" class="color-white">Price</th>
                <th scope="col" class="color-white">Quantity</th>
                <th scope="col" class="color-white">Total Price</th>
              </tr>
            </thead>
            <tbody v-for="item in order" :key="item" class="border">
              <tr>
                <th scope="row">
                  {{ item.product.name }}
                </th>
                <td>{{ item.product.price }} zł</td>
                <td>{{ item.quantity }}</td>
                <td>
                  {{ (item.quantity * item.product.price).toFixed(2) }} zł
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center">
            <h1>{{ calcSum }} zł</h1>
          </div>
        </div>

        <div class="pdf">
          <PDF :order="order" />
        </div>
        <div class="d-flex justify-content-center">
          <button
            type="button"
            class="
              btn btn-sm btn-left-l
              bg-red-dark
              text-uppercase
              font-900
              text-uppercase
              rounded-s
              shadow-xl
              mb-4
            "
            @click="back"
          >
            Back
          </button>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PDF from "@/components/PDF.vue";

export default {
  components: { PDF },
  data() {
    return {
      order: [],
      orderDetail: {},
    };
  },
  mounted() {
    this.getOrder();
  },

  methods: {
    getOrder() {
      const order_id = this.$route.params.id;
      axios
        .get(`api/order/history/${order_id}/`)
        .then((response) => {
          this.order = response.data.items;
          this.orderDetail = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    back() {
      this.$router.push({ name: "History" });
    },
  },

  computed: {
    calcSum() {
      return this.order
        .reduce((acc, curVal) => {
          return (acc += curVal.product.price * curVal.quantity);
        }, 0)
        .toFixed(2);
    },
  },
};
</script>
